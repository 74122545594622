@media (min-width: 310px){
  .makeStyles-title-2{
    font-size: 1.8rem !important;
  }
  .imgBody{
    height: 33vh !important;
  }
}

@media (min-width: 449px){
  .makeStyles-title-2{
    font-size: 3.3125rem !important;
  }
  .imgBody{
    height: 36vh !important;
  }
}

@media (min-width: 500px){
  .makeStyles-title-2{
    font-size: 3.3125rem !important;
  }
  .imgBody{
    height: 42vh !important;
  }
}

@media (min-width: 579px){
  .makeStyles-title-2{
    font-size: 3.3125rem !important;
  }
  .imgBody{
    height: 47vh !important;
  }
}

@media (min-width: 750px) {
  .makeStyles-title-2{
    font-size: 3.3125rem !important;
  }
  .imgBody{
    height: 60vh !important;
  }
}

@media (min-width: 960px){
  .makeStyles-title-2{
    font-size: 3.3125rem !important;
  }
  .imgBody{
    height: 80vh !important;
  }
}
